.placeholder-font::placeholder{
  font-size: 14px;
}

.div_ariane_stats{
  display: flex;
}

.btn-hover-blue:hover {
  background-color: #0d6efd !important;
  color: white; 
}

@media (max-width: 600px){

  .div_ariane_stats{
    flex-direction: column;
  }

}
