/* styles.css */
.filter-input, .complexity-input, .priority-input {
    height: 25px;
    width: 100%; /* Utilisez 100% pour s'adapter à la largeur de la colonne */
  }
  
  .action-btn {
    margin-right: 5px; /* Espacement entre les boutons */
  }
  
  /* Ajustez la largeur des colonnes pour les sélecteurs et les entrées */
  .select-filter, .form-control {
    text-align: center;
    height: 10px;
    min-width: 20px; /* Ajustez selon le besoin */
  }
  
  /* Style pour les en-têtes de colonnes pour un alignement visuel cohérent */
  .table-header {
    cursor: pointer;
  }
  .custom-table-container {
    padding: 20px;
    border-radius: 5px;
    overflow-x: auto;
  }
  
  .text-center {
    text-align: center;
  }
  
  .sort-icon {
    margin-left: 5px;
  }
  
  .filter-input, .filter-select {
    height:25px; 
    width: 100%; /* Ajustez la largeur en fonction de la largeur désirée pour les inputs/selects */
    margin: auto; /* Centre les inputs/selects */
  }
  
  /* Ajoutez d'autres styles CSS ici si nécessaire */
  .table td {
    max-width: 200px; /* ajustez la largeur maximale selon vos besoins */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .filAriane{
    display:flex; 
    align-items:left; 
    width:100%; 
    justify-content:space-between;
  }

  @media (max-width: 600px){
    
    .filAriane{
      display:initial; 
    }

  }
  
